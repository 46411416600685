/* .loop-surveys-list{
    list-style: none;
    column-count: 4;
} */
.loop-surveys-div{
    top: 100px;
}
.loop-surveys-div ul:nth-child(1){
    padding-top: 10px;
}
.loop-surveys-div ul{
    list-style: none;
    column-count: 4;
    border-bottom: 1px solid #ddd;
    padding-bottom: 18px;
}
.loop-surveys-div ul:last-child{
    border-bottom: none;
    padding-bottom: 0px;
}
.btn-zamp-color{
    border-color: #1173a0;
    background: #1173a0;
}
.btn-zamp-color:hover, .btn-zamp-color:focus {
    color: #fff;
    border-color: #025981;
    background: #025981;
}
.short-title{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.loop-surveys-div{
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}