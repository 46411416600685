:root {font-size:16px;     height: 100%;}




:root{
	font-size:16px;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

body{
	background:url(../../Image/bg.jpg);
	background-size:cover;
	margin:0px;
	font-family: 'Open Sans', sans-serif;
	font-weight:400;
	height:100%;
}
.animate-bottom{
	display:block;

	    height: 100vh;
		position:relative;
		overflow:hidden;
		
}
.round{
	max-width:50%;
	text-align:center;
	       height: 100%;
		    width: 50%;
			padding-top:12%;
}

.round h2{
	color:#fff;
	font-weight:600;
	font-size:2rem;
}
.round p{
	color:#fff;
	font-size:1.25rem;
}
.right-image-wrapper{
	position:relative;
}
.right-image{
	position:absolute;
	right:-800px;
	background:#3aa4f9;
	bottom:0px;
	top:0px;
	width:1545px;
	height:1700px;
	border-radius:50%;
	top:0px;
	margin:auto;
	
}
.right-image:before{
	content:"";
	position:absolute;
	right:0px;
	background:#fff;
	bottom:0px;
	top:0px;
	width:1600px;
	height:1600px;
	border-radius:50%;
	top:0px;
	left:10px;

	
}
.right-image img{
	       position: absolute;
  
	
    /* z-index: 1; */
    top: 0;
    left: -40%;
    right: 0;
    bottom: 0;
    margin: auto;
   
}
#loader{
	    background: rgba(0,0,0,0.5);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
	z-index:1;
	margin:auto;
	
}


#loader .round{
	padding:3rem;
margin:auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    height: 70vh;
	max-height: 500px;
    max-width: 700px;
    background: #fff;
	-webkit-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.39);
-moz-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.39);
box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.39);

}

.round-circle{
	 display: inline-block;
	border: 20px solid rgb(216,216,216);
  border-radius: 50%;
  border-top:20px solid rgb(9, 255, 194);
 width: 150px;
    height: 150px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
#myDiv {
  display: none;
  
}





@media(max-width:1480px){
	.round{
	max-width:50%;
	text-align:center;
	    align-self: center;
		    width: 50%;
}
.right-image{
	    width: 1400px;
    height: 1760px;
	    right: -700px;
}
}

@media(max-width:1366px){
	
	.right-image{
	    width: 990px;
   
	    right: -532px;
}
.right-image:before{
	  width: 1000px;
}
.right-image img{
	    max-width: 350px;
}
.round {
    max-width: 100%;
    text-align: center;
    align-self: center;
    width: 62%;
}
.round{
	    padding-top: 0;
    height: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}

}

@media(max-width:1180px){
	.right-image {
    width: 900px;
   
    right: -450px;
}
.right-image img{
	    left: -47%;
}
.round{
	    width: 50%;
    padding-left: 30px;
    padding-left: 30px;
	bottom: 200px;
}
.round br{
	display:none;
}
}





@media(max-width:1180px){
	

}



@media(max-width:990px){
	.right-image{
		width: 794px;
	}
	.right-image:before {
		width: 794px;
	}
	.p-toast {
		width: 98vw;
	}
	.p-toast-top-right {
		right: 5px;
	}
}


@media (min-width:0px) and (max-width:824px) and (orientation:landscape){
	
.round{
	    padding: 20px;
		height:auto;
		    top: 12%;
}
.right-image {
    width: 500px;
	height:680px;
	    right: -230px;
}
.right-image:before{
	width: 500px;
	height:600px;
}
.right-image img {
    max-width: 250px;
	    left: -43%;
}
.animate-bottom{
	    overflow: hidden;
		   
}
.round-circle {
    width: 80px;
    height: 80px;
}
.round p{
		
		font-size:1rem;}
		.round h2{
			font-size:1.25rem;
			}
}



@media(max-width:767px){
	
			

}





@media(max-width:620px){
	
	
	
}








@media(max-width:576px){
	.round{
	    top: 0;
	
}
}





@media(max-width:480px){
	.round-circle{
	width:80px;
	height:80px;
}
	
	.round {
    padding-top: 0;
    height: auto;
    position: relative;
       top: 0px;
    bottom: 0;
    margin: auto;
   width: auto;
    padding-left: 20px;
        padding-top: 20%;
    padding-bottom: 20px;
    padding-right: 20px;
}
 .right-image{
	      width: 350px;
    height: 350px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
	    top: auto;
}
.right-image:before{
    width: 350px;
    height: 350px;
	position: absolute;
}
.right-image img{
	left:0px;
	    max-width: 270px;
}
.round p{
		
		font-size:1rem;}
		.round h2{
			font-size:1.25rem;
			}
}




@media(max-width:420px){
	
	.right-image{
		    width: 300px;
    height: 300px;
    position: absolute;
    bottom: -150px;}
	.right-image:before {
    width: 400px;
    height: 400px;
    position: absolute;
}
}







@media(max-width:360px){

}









@media(max-width:320px){
	.right-image{
	width: 300px;
	height: 300px;
    position: absolute;
    bottom: -150px;}
	.right-image:before {
    width: 400px;
    height: 400px;
    position: absolute;
}
.round{
	    padding-top: 20px;	
}

		
}




@media (min-width:1024px) and (max-width:1366px) and (orientation:landscape){
	
}
@media (min-width:1024px) and (max-width:1366px) and (orientation:portrait){
	
	
}

@media (min-width:421px) and (max-width: 540px){
	.right-image {
		width: 660px;
	}
	.round{
		bottom: 260px;
	}
}